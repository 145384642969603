<template>
  <div class="order-detail">
    <v-dialog
      v-model="xenditPayment"
      width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-md">Pay with Xendit</span>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <v-card-title>
            <span class="text-sm">Please enter your email below</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="buyerEmail"
              label="Your Email"
              color="#EA7C69"
              outlined
              dense
              hide-details
              height="10px"
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="cancelXenditPayment"
          >
            Cancel
          </v-btn>

          <v-btn
            color="success"
            @click="payWithXendit"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="openPayment"
      width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <div>
            Payment
          </div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <div class="height-dialog">
            <v-card-text
              v-if="dataShop.data"
              class="my-3"
            >
              <v-btn
                color="success"
                block
                @click="proceedOrder()"
              >
                Cash
              </v-btn>

              <v-btn
                v-if="dataShop.data.pricing.enableXendit && dataShop.data.pricing.keyXendit"
                class="my-3"
                block
                color="primary"
                @click="xenditPayment = true"
              >
                XENDIT / E-WALLET
              </v-btn>
              <div id="paypal-buttons"></div>
            </v-card-text>
            <!-- <v-card-text class="my-3"  v-if="dataShop.data === false">
             <v-btn
              color="success"
              block
            >
              Cash
            </v-btn>
           </v-card-text> -->
          </div>

          <!-- </v-card-text> -->
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error"
              @click="cancelPayment"
            >
              Cancel
            </v-btn>
          </v-card-actions>
          <!-- </v-form>
          </v-card-text> -->
        </v-card>
      </v-card>
    </v-dialog>
    <div class="header-order mt-2 d-flex justify-space-between pe-3">
      <div class="header-order-text d-flex ms-3">
        <div class="header-order-navigate mt-2 me-2">
          <v-icon
            class=""
            color="#EA7C69"
            @click="backToOrderPage"
          >
            {{ icons.mdiArrowLeft }}
          </v-icon>
        </div>
        <div class="mt-2 mb-3">
          <span class="header-title">Checkout</span>
        </div>
      </div>
    </div>
    <div class="order-pass">
      <div class="order-courir">
        <div class="ps-5 pe-3 mb-1">
          <span class="variant-title ms-3">Order Type</span>
        </div>
        <div class="ps-8 pe-5">
          <div
            v-if="orderType.length === 0"
            class="d-flex"
          >
            <v-skeleton-loader
              v-for="index2 in 2"
              :key="index2"
              class="me-4"
              v-bind="attrs"
              type="chip"
            ></v-skeleton-loader>
          </div>
          <div
            v-for="item in orderType"
            v-else
            :key="item.id"
            :class="selectType.name === item.name ? 'filter-tools-active' : 'filter-tools'"
            @click="selectByType(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-if="selectType.name === 'Dine In'">
          <div class="ps-7 pe-7 mb-1 mt-3 order-form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="dineIn.tableId"
                  label="Table No."
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  height="10px"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="dineIn.cover"
                  label="Cover"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  height="10px"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="dineIn.note"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  label="Note"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="selectType.name === 'Room Order'">
          <div class="ps-7 pe-7 mb-1 mt-3 order-form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="roomOrder.roomNo"
                  label="Room No."
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  height="10px"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="roomOrder.cover"
                  label="Cover"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  height="10px"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="roomOrder.note"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  label="Note"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="selectType.name === 'Take Away'">
          <div
            class="ps-7 pe-7 mb-1 mt-3 order-form"
          >
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="takeAway.note"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  label="Note"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="selectType.name === 'Delivery'">
          <div class="ps-7 pe-7 mb-1 mt-3 order-form">
            <v-row v-if="dataShop.data.address.enableDeliveryPricing">
              <v-col cols="6">
                <v-select
                  v-model="address.province"
                  :items="provinces.data"
                  label="Province"
                  item-text="province"
                  return-object
                  hide-details
                  :rules="[v => !!v || 'Item is required']"
                  required
                  dense
                  outlined
                  item-value="value"
                  @change="onChangeProvince()"
                ></v-select>
                <!-- <v-text-field
                  v-model="geoCode"
                  label="GeoCode"
                  color="#EA7C69"
                  outlined
                  dense
                  readonly
                  hide-details
                  height="10px"
                ></v-text-field> -->
                <!-- <p>
                  {{
                    center ? `Lat: ${center.lat.toFixed(2)} | Lng: ${center.lng.toFixed(2)}` : 'Position not provided.'
                  }}
                </p> -->
              </v-col>
              <v-col cols="6">
                <!-- <v-btn
                  color="#EA7C69"
                >
                  Get Geocode
                </v-btn> -->
                <v-select
                  v-model="address.city"
                  :disabled="address.province.province === undefined ? true : false"
                  :items="cityData"
                  label="City"
                  item-text="text"
                  return-object
                  :rules="[v => !!v || 'Item is required']"
                  required
                  dense
                  outlined
                  item-value="value"
                  @change="onChangeCity()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="dataShop.data.address.enableDeliveryPricing && dataShop.data.address.availableCourier.length !== 0 "
              style="margin-top: -30px"
            >
              <v-col cols="6">
                <v-select
                  v-model="address.subDistrict"
                  :disabled="address.city.city_id === undefined ? true : false"
                  :items="subdistricts"
                  label="Subdistrict"
                  return-object
                  hide-details
                  :rules="[v => !!v || 'Item is required']"
                  required
                  dense
                  item-text="subdistrict_name"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="address.courier"
                  :disabled="address.city.province_id === undefined ? true : false"
                  :items="dataShop.data.address.availableCourier"
                  label="Courier"
                  return-object
                  hide-details
                  :rules="[v => !!v || 'Item is required']"
                  required
                  dense
                  outlined
                  item-value="value"
                  @change="onChangeCourier()"
                ></v-select>
                <!-- <v-select
                        :items="provinces.data"
                        label="Province"
                        item-text="province"
                        v-model="address.province"
                        return-object
                          hide-details
                        :rules="[v => !!v || 'Item is required']"
                        required
                        dense
                        outlined
                        item-value="value"
                        @change="onChangeProvince()"
                      ></v-select> -->
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="address.delivery"
                  :disabled="!courierService.status"
                  :items="listService"
                  label="Service"
                  item-text="service"
                  return-object
                  hide-details
                  :rules="[v => !!v || 'Item is required']"
                  required
                  dense
                  outlined
                  item-value="value"
                  @change="onChangeService()"
                ></v-select>

                <!-- <v-select
                        :disabled="address.province.province === undefined ? true : false"
                        :items="cityData"
                        label="city"
                        item-text="city_name"
                        v-model="address.city"
                        return-object
                        :rules="[v => !!v || 'Item is required']"
                        required
                        dense
                        outlined
                        item-value="value"
                      ></v-select> -->
              </v-col>
              <!-- </v-col> -->
            </v-row>
            <v-row v-if="listTypeService.length !== 1">
              <v-col cols="6">
                <v-select
                  v-model="address.serviceType"
                  :items="listTypeService"
                  label="Type"
                  item-text="etd"
                  return-object
                  hide-details
                  required
                  dense
                  outlined
                  item-value="value"
                ></v-select>
                <!-- <v-select
                        :items="provinces.data"
                        label="Province"
                        item-text="province"
                        v-model="address.province"
                        return-object
                          hide-details
                        :rules="[v => !!v || 'Item is required']"
                        required
                        dense
                        outlined
                        item-value="value"
                        @change="onChangeProvince()"
                      ></v-select> -->
              </v-col>
              <v-col cols="6">
                <!-- <v-select
                       :disabled="!courierService.status"
                        :items="listService"
                        label="Service"
                        item-text="service"
                        return-object
                        hide-details
                        :rules="[v => !!v || 'Item is required']"
                        required
                        dense
                        outlined
                        v-model="address.delivery"
                        item-value="value"
                          @change="onChangeService()"
                      ></v-select> -->

                <!-- <v-select
                        :disabled="address.province.province === undefined ? true : false"
                        :items="cityData"
                        label="city"
                        item-text="city_name"
                        v-model="address.city"
                        return-object
                        :rules="[v => !!v || 'Item is required']"
                        required
                        dense
                        outlined
                        item-value="value"
                      ></v-select> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="address.detail"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  label="Address Detail"
                  rows="3"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="address.note"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  label="Note"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="selectType.name === 'Reservation'">
          <div class="ps-7 pe-7 mb-1 mt-3 order-form">
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="reservation.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reservation.date"
                      label="Reservation Date"
                      color="#EA7C69"
                      outlined
                      dense
                      hide-details
                      height="10px"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reservation.date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="validateDate(reservation.date)"
                    >
                      OK
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="validateDate(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="reservation.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reservation.time"
                      label="Reservation Time"
                      color="#EA7C69"
                      outlined
                      dense
                      readonly
                      hide-details
                      height="10px"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="reservation.time"
                    :allowed-minutes="allowedMinutes"
                    format="24hr"
                    color="#ea7c69"
                    full-width
                    @click:minute="$refs.menu2.save(reservation.time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="reservation.cover"
                  label="Cover"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  height="10px"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="reservation.note"
                  color="#EA7C69"
                  outlined
                  dense
                  hide-details
                  label="Note"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div class="order-variant mt-2 mb-3">
        <div class="ps-5 pe-3">
          <span class="variant-title ms-3">Order Summary</span>
        </div>
        <div class="mt-3 ps-7 pe-7">
          <div v-if="orderCart.length === 0">
            <v-skeleton-loader
              v-for="index3 in 5"
              :key="index3"
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </div>
          <!-- loop dari sini -->
          <div
            v-for="(item, indexOrder) in orderCart"
            :key="indexOrder + 'order'"
          >
            <v-row class="ps-3 row-order mt-2">
              <v-col
                cols="8"
                class="d-flex text-max-width"
              >
                <div>
                  <img
                    :src="item.itemDetail.Photo !== null ? item.itemDetail.Photo : 'https://i.stack.imgur.com/81VAF.jpg'"
                    alt=""
                    style="object-fit: cover;"
                    width="50px"
                    height="50px"
                  />
                </div>
                <div class="ms-3">
                  <div class="checkout-order-title">
                    <strong class="checkout-order-amount">{{ item.qtyItem }}x</strong> {{ item.itemDetail.Name }}
                  </div>
                  <div
                    v-if="item.itemDetail.Name !== item.variant.Name"
                    class="checkout-order-detail my-2"
                  >
                    {{ item.variant.Name }}
                  </div>
                  <div class="checkout-order-detail">
                    {{ item.itemAddOn.join(', ') }}
                  </div>
                </div>
              </v-col>
              <v-col
                cols="4"
                class="text-end pe-5"
              >
                <div class="checkout-order-price pt-5">
                  Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(item.totalPrice) }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
          </div>
          <!-- sampai sini -->
        </div>
      </div>
    </div>
    <!-- Order Checkout -->
    <div class="order-variant-total">
      <div v-if="orderCart.length === 0">
        <v-skeleton-loader
          v-bind="attrs"
          max-width="1000"
          class="px-4 py-4"
          type="image"
        ></v-skeleton-loader>
      </div>
      <div v-if="orderCart.length !== 0">
        <div class="ps-3 pe-2">
          <v-row class="order-checkout-row">
            <v-col cols="6">
              <div class="order-checkout-payment">
                Subtotal:
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-end"
            >
              <div class="order-checkout-payment">
                Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(pricing.subtotal) }}
              </div>
            </v-col>
          </v-row>
          <v-row class="order-checkout-row">
            <v-col cols="6">
              <div class="order-checkout-payment">
                Order Fee:
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-end"
            >
              <div class="order-checkout-payment">
                Rp. 0
              </div>
            </v-col>
          </v-row>
          <!-- <v-row
            v-if="this.address.serviceType.etd !== undefined"
            class="order-checkout-row"
          >
            <v-col cols="6">
              <div class="order-checkout-payment">
                Total Weight:
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-end"
            >
              <div class="order-checkout-payment">
                <span v-if="this.address.serviceType.etd !== undefined">
                  {{ convertWeight(weightData) }}
                </span>
              </div>
            </v-col>
          </v-row> -->
          <v-row class="order-checkout-row">
            <v-col cols="6">
              <div class="order-checkout-payment">
                Delivery:
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-end"
            >
              <div class="order-checkout-payment">
                <span v-if="this.address.serviceType.etd !== undefined">{{ this.address.serviceType.etd }} Hari</span><span v-if="this.address.serviceType.etd !== undefined">
                  Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(this.address.serviceType.value) }}
                </span>
                <span v-if="this.address.serviceType.etd === undefined"> Rp. 0 </span>
              </div>
            </v-col>
          </v-row>
          <v-row class="order-checkout-row">
            <v-col
              cols="6"
            >
              <div class="order-checkout-payment">
                Pajak ({{ dataShop.data.tax }})%:
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-end"
            >
              <div class="order-checkout-payment">
                Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(pricing.tax) }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="order-checkout-payment">
                Total:
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-end"
            >
              <div class="order-checkout-payment">
                Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(pricing.total) }}
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-3 mb-3" />

        <div class="ps-3 pe-2">
          <v-row class="order-checkout-row">
            <v-col cols="4">
              <div class="order-checkout-payment">
                Discount :
              </div>
            </v-col>
            <v-col
              cols="8"
              class="text-end"
            >
              <div class="order-checkout-voucer">
                <span
                  v-if="!isAddVoucher"
                  @click="isAddVoucher = true"
                >Add Discount Voucher</span>
                <span
                  v-if="pricing.disc !== 0"
                  class="order-checkout-payment"
                >Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(pricing.disc) }}</span>
                <div
                  v-if="isAddVoucher"
                  class="d-flex me-1 text-end justify-end"
                >
                  <div class="me-2">
                    <v-text-field
                      v-model="inputCoupon"
                      type="text"
                      dense
                      color="#ea7c69"
                      clearable
                    ></v-text-field>
                  </div>
                  <div class="pt-2">
                    <v-btn
                      x-small
                      color="#ea7c69"
                      @click="CheckVoucher()"
                    >
                      Check
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div
          v-if="orderCart.length !== 0"
          class="footer pt"
        >
          <div
            class="btn-footer text-center"
            @click="validateOrder"
          >
            <span
              class="footer-text"
            >Order Now</span>
          </div>
        </div>
      </div>
      <v-snackbar
        v-model="errMessage"
        color="error"
        :timeout="2000"
        :top="true"
        style="z-index: 5 !important"
      >
        {{ errorMessageSend }}
        <template #action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="errMessage = false"
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="failedXenditPayment"
        color="warning"
        :timeout="2000"
        :top="true"
        style="z-index: 5 !important"
      >
        {{ failedXenditMessage }}
        <template #action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="failedXenditPayment = false"
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-overlay :value="loadingXendit">
        <v-progress-circular
          :size="50"
          color="#ea7c69"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {
  mdiScriptTextOutline,
  mdiArrowLeft,
  mdiMagnify,
  mdiViewListOutline,
  mdiViewGridOutline,
  mdiPlus,
} from '@mdi/js'

// import { gmapsMap } from 'x5-gmaps'

export default {
  // components: { gmapsMap },
  setup() {
    return {
      icons: {
        mdiScriptTextOutline,
        mdiArrowLeft,
        mdiMagnify,
        mdiViewListOutline,
        mdiViewGridOutline,
        mdiPlus,
      },
      dataShop: {},
    }
  },
  data: () => ({
    openPayment: false,
    inputCoupon: '',
    attrs: '',
    errMessage: false,
    errorMessageSend: '',
    center: null,
    geoCode: '',
    time: null,
    buyerEmail: '',
    menu2: false,
    loadingXendit: false,
    xenditPayment: false,
    failedXenditPayment: false,
    failedXenditMessage: '',
    modal2: false,
    isAddVoucher: false,
    dineIn: {
      tableId: '',
      cover: '',
      note: '',
    },
    roomOrder: {
      roomNo: '',
      cover: '',
      note: '',
    },
    takeAway: {
      note: '',
    },
    reservation: {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      time: '',
      cover: '',
      note: '',
    },
    address: {
      city: {},
      province: {},
      detail: '',
      courier: '',
      delivery: {},
      serviceType: {},
      note: '',
    },
    weightData: 0,
    selectType: {},
    cityData: [],

    courierService: {
      status: false,
    },
    listService: [],
    subdistricts: [],
    listTypeService: [{}],
    pricing: {
      subtotal: 0,
      fee: 0,
      delivery: 0,
      total: 0,
      disc: 0,
      tax: 0,
    },
    orderCart: [],
    orderType: [],

    // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    currentInterval: 0,
    currentId: '',
    intervalCheckInvoice: null,
    coupon: [],
    couponValidation: false,
    deliveryWithoutCourier: false,
  }),
  computed: {
    provinces() {
      return this.$store.getters['eOrdering/getProvince']
    },
    cities() {
      const cities = this.$store.getters['eOrdering/getCity']

      cities.forEach(city => {
        city.text = `${city.type} ${city.city_name}`
      })

      return cities
    },
    currentConvertion() {
      return this.$store.getters['eOrdering/getCurrentConvertion']
    },
  },
  async mounted() {
    this.pixelRun()
    this.currentId = `order-${this.$nanoid().toLowerCase()}`

    // localStorage.removeItem(this.$route.params.name)
    await this.$store.dispatch('eOrdering/getProvinceRajaOngkir')
    await this.$store.dispatch('eOrdering/getCityRajaOngkir')

    this.$vuetify.theme.isDark = true
    const shop = await this.$store.dispatch('eOrdering/getShopDetail', { slug: this.$route.params.name })
    this.dataShop = shop.shopData

    // console.log(this.dataShop)

    // console.log(shop, `INI SHOP`)
    this.orderType = this.dataShop.data.shopType.value.orderTypes
    this.selectType = this.dataShop.data.shopType.value.orderTypes[0]
    this.coupon = shop.couponShop
    this.orderCart = JSON.parse(localStorage.getItem(this.$route.params.name))
    this.countPricing()

    this.orderType.forEach(el => {
      if (this.dataShop.data.address.enableDeliveryPricing === false && el.name === 'Delivery') {
        // console.log(true, `BENER NGAGK`)
        this.deliveryWithoutCourier = true
      }
    })
  },
  beforeDestroy() {
    if (this.intervalCheckInvoice) clearInterval(this.intervalCheckInvoice)
  },
  methods: {
    cancelXenditPayment() {
      this.xenditPayment = false
      this.buyerEmail = ''
    },
    async payWithXendit() {
      this.loadingXendit = true
      if (this.dataShop?.data?.pricing?.enableXendit && this.dataShop?.data?.pricing?.keyXendit) {
        const payloadCreateInvoice = {
          id: this.currentId,
          email: this.buyerEmail,
          description: `Invoice for buying on ${this.dataShop?.data?.name} shop`,
          amount: Number(this.pricing.total),
          keyXendit: this.dataShop?.data?.pricing?.keyXendit,
        }
        this.cancelXenditPayment()
        this.openPayment = false
        const res = await this.$store.dispatch('eOrdering/createInvoiceXendit', payloadCreateInvoice)
        if (res.status === true) {
          window.open(res.data.invoice_url)
          const payloadCheckInvoice = {
            invoiceId: res.data.id,
            keyXendit: this.dataShop?.data?.pricing?.keyXendit,
          }

          this.intervalCheckInvoice = window.setInterval(async () => {
            const checkInvoiceStatus = await this.$store.dispatch('eOrdering/getInvoiceXendit', payloadCheckInvoice)
            this.currentInterval += 20000
            if (this.currentInterval > 300000) {
              this.loadingXendit = false
              this.failedXenditPayment = true
              this.failedXenditMessage = 'Payment Failed, please try again'
              clearInterval(this.intervalCheckInvoice)
            } else if (this.currentInterval <= 300000 && checkInvoiceStatus.data.status === 'PAID') {
              this.loadingXendit = false
              clearInterval(this.intervalCheckInvoice)
              this.proceedOrder()
            }
          }, 20000)
        }
      }
    },
    cancelPayment() {
      const el = document.getElementById('paypal-buttons')

      while (el.firstChild) {
        el.firstChild.remove()
      }
      this.openPayment = false
    },
    pixelRun() {
      // console.log('masukkkkkkkkk')
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
      fbq('init', this.$store.getters['eOrdering/getFbPixelSave'])
      fbq('track', 'PageView')
    },
    convertWeight(weight) {
      return `${(weight / 1000).toFixed(2)} kg (${weight} g)`
    },
    CheckVoucher() {
      // THISISCOUPON
      const couponCheck = this.coupon.find(el => {
        return el.Code === this.inputCoupon
      })

      // console.log(this.inputCoupon)
      if (couponCheck) {
        if (this.couponValidation) {
          this.pricing.total = this.pricing.total + this.pricing.disc
        }

        // console.log(couponCheck)
        const startDate = +new Date(couponCheck['Start Date'])
        const startDateToday = +new Date()
        const expiredDate = +new Date(couponCheck['Expired Date'])
        if (
          this.pricing.subtotal >= couponCheck['Minimum Buy'] &&
          couponCheck.Status === 'Active' &&
          startDateToday >= startDate &&
          startDateToday <= expiredDate
        ) {
          let counDisc = 0

          // console.log(Number(couponCheck['Discount Percentage'].replace('%', '')) / 100, `INI DISKON`)
          counDisc = this.pricing.subtotal * (Number(couponCheck['Discount Percentage'].replace('%', '')) / 100)
          if (counDisc > couponCheck['Max Discount']) {
            counDisc = couponCheck['Max Discount']
          }
          this.couponValidation = true
          this.pricing.disc = counDisc
          this.countPricingDelivery()
        } else {
          this.errorMessageSend = 'Ops.., Coupon is Not available. Please Check the Coupon again'
          this.errMessage = true
          if (this.couponValidation) {
            this.pricing.total = this.pricing.total + this.pricing.disc
            this.couponValidation = false
          }
        }
      } else {
        if (this.couponValidation) {
          this.pricing.total = this.pricing.total + this.pricing.disc
          this.couponValidation = false
        }
        this.errorMessageSend = 'Ops.., Coupon is Not available'
        this.errMessage = true
        this.pricing.disc = 0
        this.countPricingDelivery()
      }
    },
    onChangeProvince() {
      this.address.city = {}
      this.address.delivery = {}
      this.courierService = {
        status: false,
      }

      const data = this.cities.filter(el => el.province_id === this.address.province.province_id)
      this.cityData = data
    },
    async onChangeCity() {
      this.address.delivery = {}
      this.address.courier = ''
      this.courierService = {
        status: false,
      }

      this.subdistricts = await this.$store.dispatch('eOrdering/getSubdistrictRajaOngkir', {
        cityId: this.address.city.city_id,
      })
    },
    async onChangeCourier() {
      this.address.delivery = {}
      this.courierService = {
        status: false,
      }
      this.weightData = 0
      const orderData = JSON.parse(localStorage.getItem(this.$route.params.name))
      orderData.forEach(el => {
        this.weightData += el.weightItem
      })
      if (this.weightData === 0) {
        this.weightData = 100
      }

      // else if (weightData >= 30000) {
      //   this.errorMessageSend = 'Ops.., Maximum Weight Item has been reached'
      //   this.errMessage = true
      // }

      // console.log(weightData, `INI DATA WEIGHT`)

      // console.log(this.address.subDistrict.subdistrict_id, `INI SUB ID`)
      // console.log(
      //   {
      //     origin: this.dataShop.data.address.city.city_id,
      //     originType: 'city',
      //     destination: this.address.subDistrict.subdistrict_id,
      //     destinationType: 'subdistrict',
      //     weight: weightData,
      //     courier: this.address.courier.toLowerCase(),
      //   },
      //   `INI PAYLOAD`,
      // )

      const dataOngkir = await this.$store.dispatch('eOrdering/getCostRajaOngkir', {
        origin: this.dataShop.data.address.city.city_id,
        originType: 'city',
        destination: this.address.subDistrict.subdistrict_id,
        destinationType: 'subdistrict',
        weight: this.weightData,
        courier: this.address.courier.toLowerCase(),
      })

      if (dataOngkir.data.data.length === 0) {
        this.errorMessageSend = 'Oops.., courier is not available'
        this.errMessage = true
        this.address.serviceType = {}
        this.pricing.total = this.pricing.subtotal - this.pricing.disc
      } else {
        this.courierService = {
          status: dataOngkir.status,
        }

        this.listService = dataOngkir.data.data
        this.address.delivery = dataOngkir.data.data[0]
        this.onChangeService()
      }
    },
    onChangeService() {
      this.address.serviceType = this.address.delivery.cost[0]
      this.listTypeService = this.address.delivery.cost

      this.countPricingDelivery()
    },
    async openPaymentDialog() {
      this.openPayment = true

      if (this.dataShop?.data?.pricing?.enablePaypal && this.dataShop?.data?.pricing?.keyPaypal) {
        await this.$store.dispatch('eOrdering/convertIdrToUsd')
        window
          .paypalLoadScript({
            'client-id': this.dataShop.data.pricing.keyPaypal,
          })
          .then(paypal => {
            const orderData = JSON.parse(localStorage.getItem(this.$route.params.name))

            // console.log(this.address, this.dineIn, this.reservation, this.roomOrder, this.takeAway, `INI PAYLOAD2`)
            let text = ''
            let ppn = 0
            const allName = []
            const dataContent = []
            let totalItem = 0
            if (this.dataShop.data.tax !== '') {
              ppn = this.pricing.total * Number(this.dataShop.data.tax / 100)
            }

            orderData.forEach((el, i) => {
              const objContent = {
                id: el.itemDetail.id,
                quantity: el.qtyItem,
              }
              allName.push(el.itemDetail.Name)
              dataContent.push(objContent)
              totalItem += el.qtyItem

              // console.log(el, `INI EL`)
              el.addonPrice = 0
              if (el.addOn.length !== 0) {
                el.addOn.forEach(addon => {
                  el.addonPrice += addon.Price
                })
              }
              text += `*${i + 1}. ${el.itemDetail.Name}*
Quantity : ${el.qtyItem} Pcs
Variant : ${el.variant.Name ? el.variant.Name : '-'}
Add On : ${el.itemAddOn.length !== 0 ? el.itemAddOn.join(', ') : '-'}
Add On Price : Rp. ${new Intl.NumberFormat(['ban', 'id']).format(el.addonPrice)}
Harga (@) : Rp. ${new Intl.NumberFormat(['ban', 'id']).format(el.basePrice)}
Total Harga : Rp ${new Intl.NumberFormat(['ban', 'id']).format(el.totalPrice)}

`
            })
            let text2 = ``

            // console.log(this.inputCoupon, this.couponValidation, `INI COUPON`)

            if (this.address.serviceType.value) {
              if (ppn !== 0) {
                text2 = `Order Type : ${this.selectType.name}
Kurir : ${this.address.courier.toUpperCase()}
Alamat : ${this.address.detail}, ${this.address.city.type} ${this.address.city.city_name}, Kecamatan ${
                  this.address.subDistrict.subdistrict_name
                }, ${this.address.city.province}, ${this.address.city.postal_code}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Ongkos Kirim : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.address.serviceType.value)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              } else {
                text2 = `Order Type : ${this.selectType.name}
Ongkos Kirim : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.address.serviceType.value)}*
Kurir : ${this.address.courier.toUpperCase()}
Alamat : ${this.address.detail}, ${this.address.city.type} ${this.address.city.city_name}, Kecamatan ${
                  this.address.subDistrict.subdistrict_name
                }, ${this.address.city.province}, ${this.address.city.postal_code}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              }
            } else if (this.dineIn.tableId) {
              if (ppn !== 0) {
                text2 = `Order Type : ${this.selectType.name}
Id Meja : ${this.dineIn.tableId}
Cover : ${this.dineIn.cover}
Note : ${this.dineIn.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              } else {
                text2 = `Order Type : ${this.selectType.name}
Id Meja : ${this.dineIn.tableId}
Cover : ${this.dineIn.cover}
Note : ${this.dineIn.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              }
            } else if (this.reservation.time) {
              if (ppn !== 0) {
                text2 = `Order Type : ${this.selectType.name}
Tanggal : ${this.reservation.date}
Jam : ${this.reservation.time}
Cover : ${this.reservation.cover}
Note : ${this.reservation.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              } else {
                text2 = `Order Type : ${this.selectType.name}
Tanggal : ${this.reservation.date}
Jam : ${this.reservation.time}
Cover : ${this.reservation.cover}
Note : ${this.reservation.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              }
            } else if (this.selectType.name === 'Take Away') {
              if (ppn !== 0) {
                text2 = `Order Type : ${this.selectType.name}
Note : ${this.takeAway.note ? this.takeAway.note : '-'}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              } else {
                text2 = `Order Type : ${this.selectType.name}
Note : ${this.takeAway.note ? this.takeAway.note : '-'}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              }
            } else if (this.roomOrder.roomNo) {
              if (ppn !== 0) {
                text2 = `Order Type : ${this.selectType.name}
Nomor Kamar : ${this.roomOrder.roomNo}
Cover : ${this.roomOrder.cover}
Note : ${this.roomOrder.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              } else {
                text2 = `Order Type : ${this.selectType.name}
Nomor Kamar : ${this.roomOrder.roomNo}
Cover : ${this.roomOrder.cover}
Note : ${this.roomOrder.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              }
            } else if (this.deliveryWithoutCourier === true) {
              if (ppn !== 0) {
                text2 = `Order Type : ${this.selectType.name}
Alamat : ${this.address.detail}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              } else {
                text2 = `Order Type : ${this.selectType.name}
Alamat : ${this.address.detail}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
              }
            }

            // console.log(text2)
            const message = `Order Receipt.

${this.dataShop.data.name}
-----------------------------
${text}
${text2}

-----------------------------
No Ticket : ${this.currentId}-${this.dataShop.data.selectedContactList.id}
Shop ID : ${this.dataShop._id}
-----------------------------

`
            fbq('track', 'Purchase', {
              content_name: allName.join(', '),
              value: this.pricing.total,
              currency: 'IDR',
              content_type: 'product',
              contents: dataContent,
              num_items: totalItem,
            })

            const priceTotal = (Number(this.pricing.total) / Number(this.currentConvertion)).toFixed(2)
            const currentItem = this.$route.params.name
            const { phoneNumber } = this.dataShop.data

            paypal
              .Buttons({
                createOrder(data, actions) {
                  // Set up the transaction

                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: priceTotal.toString(),
                        },
                      },
                    ],
                  })
                },
                onApprove(data, actions) {
                  return actions.order
                    .capture()
                    .then(async orderData3 => {
                      // this.proceedOrder()
                      const encoded = encodeURI(`https://wa.me/${phoneNumber}?text=${message}`)
                      setTimeout(() => {
                        window.open(encoded)
                        localStorage.removeItem(currentItem)
                        window.location.href = `${window.location.origin}/order/${currentItem}`
                      }, 1000)
                    })
                    .catch(err => {
                      console.log(err, ' == ERROR')
                    })
                },
              })
              .render('#paypal-buttons')
          })
      }
    },
    validateOrder() {
      // console.log(this.selectType, 'aaaaaaaaaaaaaaaaa')
      if (this.selectType.name === 'Dine In') {
        if (this.dineIn.tableId === '') {
          this.errorMessageSend = 'Please fill Table ID field'
          this.errMessage = true
        } else {
          this.openPaymentDialog()
        }
      } else if (this.selectType.name === 'Take Away') {
        this.openPaymentDialog()
      } else if (this.selectType.name === 'Delivery') {
        if (!this.address.serviceType.value && this.dataShop.data.address.enableDeliveryPricing) {
          this.errorMessageSend = 'Please fill All Address Field'
          this.errMessage = true
        } else if (this.address.detail === '') {
          this.errorMessageSend = 'Please fill Address detail'
          this.errMessage = true
        } else {
          this.openPaymentDialog()
        }
      } else if (this.selectType.name === 'Reservation') {
        if (this.reservation.time === '') {
          this.errorMessageSend = 'Please Select Your Reservation Time'
          this.errMessage = true
        } else {
          this.openPaymentDialog()
        }
      } else if (this.selectType.name === 'Room Order') {
        if (this.roomOrder.roomNo === '') {
          this.errorMessageSend = 'Please fill Room No field'
          this.errMessage = true
        } else {
          this.openPaymentDialog()
        }
      }
    },
    proceedOrder() {
      const orderData = JSON.parse(localStorage.getItem(this.$route.params.name))

      // console.log(this.address, this.dineIn, this.reservation, this.roomOrder, this.takeAway, `INI PAYLOAD2`)
      let text = ''
      let ppn = 0
      const allName = []
      const dataContent = []
      let totalItem = 0
      if (this.dataShop.data.tax !== '') {
        ppn = this.pricing.total * Number(this.dataShop.data.tax / 100)
      }

      orderData.forEach((el, i) => {
        const objContent = {
          id: el.itemDetail.id,
          quantity: el.qtyItem,
        }
        allName.push(el.itemDetail.Name)
        dataContent.push(objContent)
        totalItem += el.qtyItem

        // console.log(el, `INI EL`)
        el.addonPrice = 0
        if (el.addOn.length !== 0) {
          el.addOn.forEach(addon => {
            el.addonPrice += addon.Price
          })
        }
        text += `*${i + 1}. ${el.itemDetail.Name}*
Quantity : ${el.qtyItem} Pcs
Variant : ${el.variant.Name ? el.variant.Name : '-'}
Add On : ${el.itemAddOn.length !== 0 ? el.itemAddOn.join(', ') : '-'}
Add On Price : Rp. ${new Intl.NumberFormat(['ban', 'id']).format(el.addonPrice)}
Harga (@) : Rp. ${new Intl.NumberFormat(['ban', 'id']).format(el.basePrice)}
Total Harga : Rp ${new Intl.NumberFormat(['ban', 'id']).format(el.totalPrice)}

`
      })
      let text2 = ``

      // console.log(this.inputCoupon, this.couponValidation, `INI COUPON`)

      if (this.address.serviceType.value) {
        if (ppn !== 0) {
          text2 = `Order Type : ${this.selectType.name}
Kurir : ${this.address.courier.toUpperCase()}
Alamat : ${this.address.detail}, ${this.address.city.type} ${this.address.city.city_name}, Kecamatan ${
            this.address.subDistrict.subdistrict_name
          }, ${this.address.city.province}, ${this.address.city.postal_code}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Ongkos Kirim : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.address.serviceType.value)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        } else {
          text2 = `Order Type : ${this.selectType.name}
Ongkos Kirim : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.address.serviceType.value)}*
Kurir : ${this.address.courier.toUpperCase()}
Alamat : ${this.address.detail}, ${this.address.city.type} ${this.address.city.city_name}, Kecamatan ${
            this.address.subDistrict.subdistrict_name
          }, ${this.address.city.province}, ${this.address.city.postal_code}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        }
      } else if (this.dineIn.tableId) {
        if (ppn !== 0) {
          text2 = `Order Type : ${this.selectType.name}
Id Meja : ${this.dineIn.tableId}
Cover : ${this.dineIn.cover}
Note : ${this.dineIn.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        } else {
          text2 = `Order Type : ${this.selectType.name}
Id Meja : ${this.dineIn.tableId}
Cover : ${this.dineIn.cover}
Note : ${this.dineIn.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        }
      } else if (this.reservation.time) {
        if (ppn !== 0) {
          text2 = `Order Type : ${this.selectType.name}
Tanggal : ${this.reservation.date}
Jam : ${this.reservation.time}
Cover : ${this.reservation.cover}
Note : ${this.reservation.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        } else {
          text2 = `Order Type : ${this.selectType.name}
Tanggal : ${this.reservation.date}
Jam : ${this.reservation.time}
Cover : ${this.reservation.cover}
Note : ${this.reservation.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        }
      } else if (this.selectType.name === 'Take Away') {
        if (ppn !== 0) {
          text2 = `Order Type : ${this.selectType.name}
Note : ${this.takeAway.note ? this.takeAway.note : '-'}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        } else {
          text2 = `Order Type : ${this.selectType.name}
Note : ${this.takeAway.note ? this.takeAway.note : '-'}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        }
      } else if (this.roomOrder.roomNo) {
        if (ppn !== 0) {
          text2 = `Order Type : ${this.selectType.name}
Nomor Kamar : ${this.roomOrder.roomNo}
Cover : ${this.roomOrder.cover}
Note : ${this.roomOrder.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        } else {
          text2 = `Order Type : ${this.selectType.name}
Nomor Kamar : ${this.roomOrder.roomNo}
Cover : ${this.roomOrder.cover}
Note : ${this.roomOrder.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        }
      } else if (this.deliveryWithoutCourier === true) {
        if (ppn !== 0) {
          text2 = `Order Type : ${this.selectType.name}
Alamat : ${this.address.detail}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Pajak (${this.dataShop.data.tax}%): *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.tax)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        } else {
          text2 = `Order Type : ${this.selectType.name}
Alamat : ${this.address.detail}
Note : ${this.address.note}
Coupon : ${this.couponValidation ? this.inputCoupon : '-'}
Sub Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.subtotal)}*
Total : *Rp. ${new Intl.NumberFormat(['ban', 'id']).format(this.pricing.total)}*
Source: ${this.$route.params.name}`
        }
      }

      // console.log(text2)
      const message = `Order Receipt.

${this.dataShop.data.name}
-----------------------------
${text}
${text2}

-----------------------------
No Ticket : ${this.currentId}-${this.dataShop.data.selectedContactList.id}
Shop ID : ${this.dataShop._id}
-----------------------------

`
      fbq('track', 'Purchase', {
        content_name: allName.join(', '),
        value: this.pricing.total,
        currency: 'IDR',
        content_type: 'product',
        contents: dataContent,
        num_items: totalItem,
      })

      const encoded = encodeURI(`https://wa.me/${this.dataShop.data.phoneNumber}?text=${message}`)
      setTimeout(() => {
        window.open(encoded, '_blank')
        localStorage.removeItem(this.$route.params.name)
        this.$router.push({ name: 'order' })
      }, 1000)
    },
    countPricing() {
      this.orderCart.forEach(el => {
        this.pricing.subtotal += el.totalPrice
      })
      this.pricing.total = this.pricing.subtotal - this.pricing.disc
      if (this.dataShop.data.tax) {
        this.pricing.tax = this.pricing.total * (this.dataShop.data.tax / 100)
        this.pricing.total = this.pricing.total + this.pricing.tax
      }
    },
    countPricingDelivery() {
      if (!this.address.serviceType.hasOwnProperty('value')) {
        // console.log(true, `masuk if 1`)
        this.pricing.total = this.pricing.total - this.pricing.disc
      } else {
        // console.log(false, `masuk if 2`)
        this.pricing.total = this.pricing.subtotal + this.address.serviceType.value - this.pricing.disc
      }
    },

    allowedMinutes: v => v % 30 === 0,
    backToOrderPage() {
      this.$router.push({ name: 'order' })
    },
    async selectByType(item) {
      this.selectType = item
      if (item.name === 'Delivery') {
        await this.$store.dispatch('eOrdering/getProvinceRajaOngkir')
        await this.$store.dispatch('eOrdering/getCityRajaOngkir')
      } else {
        this.address.delivery = {}
        this.address.serviceType = {}
        this.countPricingDelivery()
      }
    },
    validateDate(date) {
      const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      if (new Date(today).getTime() / 1000 <= new Date(date).getTime() / 1000) {
        this.$refs.menu.save(date)
      } else {
        this.errorMessageSend = 'Please pick proper Date'
        this.errMessage = true
      }
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400;1,500;1,600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
.order-detail {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}
.header-order {
  color: white;
}
.order-pass {
  height: 61vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.header-desc {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.img-preview {
  padding-top: 25px;
}
.img-preview img {
  width: 162px;
  height: 162px;
  border-radius: 8px;
}
.order-detail-data {
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: #2d303e;
}
.order-list-desc-title {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.order-list-desc-text {
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.order-list-desc-price {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.order-list-desc-full {
  margin-top: -16px;
}
.order-list-desc-text-full {
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.order-variant {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: -12px;
  margin-left: -12px;

  background-color: #2d303e;
}
.order-courir {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: -12px;
  margin-left: -12px;
  background-color: #2d303e;
}
.order-variant-total {
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: #2d303e;
}
.variant-title {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.order-list-selection {
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}
.order-list-selection-price {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.qty-btn {
  background-color: #ea7c69;
  border-radius: 5px;
}
.numberOrder {
  color: white;
  padding-top: 1px;
}
.footer {
  padding: 12px 14px 8px 14px;
}
.btn-footer {
  color: white;
  padding: 12px;
  border-radius: 10px;
  background-color: #ea7c69;
}
/* .text-max-width{
  width: 60%;
} */
.checkout-order-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  width: 150px;
  line-height: 140%;
}
.checkout-order-detail {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.checkout-order-amount {
  font-family: 'Open Sans', sans-serif;
  color: #ea7c69;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
}
.checkout-order-price {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.order-checkout-payment {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.order-checkout-row {
  margin-top: -12px;
}
.order-checkout-voucer {
  font-family: 'Open Sans', sans-serif;
  color: #ea7c69;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.filter-tools-active {
  margin-top: 6px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  display: inline-block;
  color: white;
  padding: 4px 10px;
  background: #ea7c69;
  border-radius: 20px;
  margin-right: 5px;
}

.filter-tools {
  margin-top: 6px;
  margin-right: 5px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  display: inline-block;
  color: #ea7c69;
  padding: 4px 10px;
  border-radius: 20px;
  border: 1px solid rgba(231, 227, 252, 0.14);
}
.variant-tab {
  color: white;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;

  font-size: 12px;
  line-height: 140%;
}
.order-form {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.v-input__slot {
  background: #fff !important;
}
/*  */
/* @media (max-height: 968px) {
  .header-title {
    display: none;
  }
} */
</style>
